import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import Heading from 'src/components/Heading';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import WysiwygWrapper from 'src/components/WysiwygWrapper';

const Wrapper = styled('div')`
    ${theme.spacing.mobile._40('padding-top')};
    ${theme.spacing.mobile._40('padding-bottom')};
    ${theme.spacing.tablet._40('padding-top')};
    ${theme.spacing.tablet._40('padding-bottom')};
    ${theme.spacing.desktop._88('padding-top')};
    ${theme.spacing.desktop._88('padding-bottom')};

    ${below.tablet_s} {
        ${theme.spacing.mobile._12('padding-left')};
        ${theme.spacing.mobile._12('padding-right')};
    }

    ${above.tablet_s}{
        display: flex;
    }

    color: ${theme.color.black};
`;

const HeadingWrapper = styled('div')`
    ${theme.spacing.mobile._0('padding')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${above.tablet_s} {
        width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    ${above.desktop_s} {
        padding-right: 8.33vw;
    }
`;

const TextHeading = styled(Heading)`
    ${theme.fontSize.sans.mobile._24};
    ${theme.fontSize.sans.tablet._24};
    ${theme.fontSize.sans.desktop._40};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._0('margin-bottom')};
    ${theme.spacing.desktop._0('margin-bottom')};

    line-height: 1.15em;
`;

const ContentWrapper = styled('div')`
    ${theme.spacing.mobile._0('padding')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${above.tablet_s} {
        padding-right: 8.3333vw;
    }

    flex: 1;
`;

const StyledText = styled(Text)`
    line-height: 1.64em;
    font-size: 16px;
    color: ${theme.color.black};
`;

const StyledImage = styled(Image)`
    margin: 50px 0;
`;

const OuterContentWrapper = styled('div')`
`;

//Check if mobile and desktop images are availiable and returns image accordingly. If theres only one image availiable,
//it will be returned for both mobile/desktop.
const getImage = (image, size) => {
    return image.mobile.image[size] && image.desktop.image[size]
        ? [
              image.mobile.image[size][0],
              {
                  ...image.desktop.image[size][0],
                  media: `${above.tablet_s.replace('@media ', '')}`,
              },
          ]
        : image.desktop.image[size]
        ? image.desktop.image[size]
        : image.mobile.image[size];
};

const TextImage = ({data}) => {
    return (
        <Wrapper>
            <HeadingWrapper>
                <TextHeading as={data.heading.type}>
                    {data.heading.text}
                </TextHeading>
            </HeadingWrapper>
            <OuterContentWrapper>
                {
                    data.sections.map(( section ) =>
                        section.acfFcLayout === 'text_section' ?
                            <ContentWrapper>
                                <WysiwygWrapper data={section.text} textComponent={StyledText}/>
                            </ContentWrapper>
                        :
                            <ContentWrapper>
                                <StyledImage fluid={getImage(section.image, 'medium')}/>
                            </ContentWrapper>
                    )
                }
            </OuterContentWrapper>
        </Wrapper>
    );
};

TextImage.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string
        }).isRequired,
        sections: PropTypes.arrayOf(PropTypes.shape({
            acfFcLayout: PropTypes.string,
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        }))
    }).isRequired,
};

export default TextImage;